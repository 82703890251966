import './App.css'
import {Wordle} from '../Wordle'
import {WORDS} from '../resources/Words'

function App() {
  const answer = WORDS[Math.floor(Math.random() * WORDS.length)].toUpperCase()

  return (
    <div className="text-indigo-100 text-3xl flex w-full h-screen items-center justify-center bg-black bg-opacity-90 font-mono">
      <Wordle answer={answer} />
    </div>
  )
}

export default App
