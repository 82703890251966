import classNames from 'classnames'
import type {States} from '../../components/Cell'

type Props = {
  character: string
  addToGuess(s: string): void
  state: States
}

export function KeyboardLetter({character, addToGuess, state}: Props) {
  return (
    <div
      onClick={() => addToGuess(character.toUpperCase())}
      className={classNames(
        'h-14 w-8 rounded-md flex items-center justify-center hover:cursor-pointer',
        state === 'correct' ? 'bg-green' : '',
        state === 'present' ? 'bg-yellow' : '',
        state === 'empty' ? 'bg-light-grey' : '',
        state === 'wrong' ? 'bg-letter-grey' : ''
      )}
    >
      {character}
    </div>
  )
}
