import {faRotateRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {useEffect, useState} from 'react'
import {VALID_GUESSES} from './resources/ValidGuesses'
import {Board} from './features/board/Board'
import {Header} from './features/header/Header'
import {Keyboard} from './features/keyboard/Keyboard'

type Props = {
  answer: string
}

export function Wordle({answer}: Props) {
  const [guesses, setGuesses] = useState<string[]>([])
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showFailureModal, setShowFailureModal] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [answerFound, setAnswerFound] = useState(false)
  const [correctLetters, setCorrectLetters] = useState<string[]>([])
  const [presentLetters, setPresentLetters] = useState<string[]>([])
  const [usedLetters, setUsedLetters] = useState<string[]>([])

  const [currentGuess, setCurrentGuess] = useState('')

  const addToGuess = (s: string) => {
    if (currentGuess.length < 5) {
      setCurrentRowClass('')
      setCurrentGuess(currentGuess + s.toUpperCase())
    }
  }

  const backspace = () => {
    if (currentGuess.length > 0) {
      setCurrentRowClass('')
      setCurrentGuess(currentGuess.slice(0, currentGuess.length - 1))
    }
  }

  const handleGuess = () => {
    setCurrentRowClass('')
    if (!VALID_GUESSES.includes(currentGuess.toLowerCase())) {
      console.log('Invalid guess')
      setCurrentRowClass('animate-wiggle')

      return
    }
    if (currentGuess === answer.toUpperCase()) {
      const copyGuesses = [...guesses, currentGuess]
      setGuesses(copyGuesses)
      setCurrentGuess('')
      setAnswerFound(true)
      setShowSuccessModal(true)
    } else {
      console.log('not answer')
      const copyGuesses = [...guesses, currentGuess]
      setGuesses(copyGuesses)
      setCurrentGuess('')
    }
  }

  if (guesses.length === 6 && !answerFound && !showFailureModal) {
    setShowFailureModal(true)
  }

  const answerSplit = answer.split('')

  useEffect(() => {
    guesses.forEach((g: string) => {
      g.split('').forEach((l, index) => {
        if (answerSplit[index] === l && !correctLetters.includes(l)) {
          setCorrectLetters([...correctLetters, l])
        } else if (
          answerSplit.includes(l) &&
          !correctLetters.includes(l) &&
          !presentLetters.includes(l)
        ) {
          setPresentLetters([...presentLetters, l])
        } else if (!usedLetters.includes(l)) {
          setUsedLetters([...usedLetters, l])
        }
        {
        }
      })
    })
  }, [answerSplit, correctLetters, guesses, presentLetters, usedLetters])

  return (
    <>
      <div className="max-w-screen-md w-full h-screen mx-auto py-4 flex-grow flex-col justify-between items-center">
        <Header />
        <Board
          answer={answer}
          guesses={guesses}
          setGuesses={setGuesses}
          currentGuess={currentGuess}
          setCurrentGuess={addToGuess}
          currentRowClass={currentRowClass}
        />
        <Keyboard
          addToGuess={addToGuess}
          handleGuess={handleGuess}
          backspace={backspace}
          usedLetters={usedLetters}
          correctLetters={correctLetters}
          presentLetters={presentLetters}
        />
      </div>
      <div
        className={classNames(
          'absolute -translate-y-32 w-56 h-56 bg-black bg-opacity-90 rounded-xl flex flex-col gap-8 items-center justify-center',
          showSuccessModal ? 'block' : 'hidden'
        )}
      >
        <h1>Correct!</h1>
        <h5 className="text-sm">The word was &apos;{answer}&apos;</h5>
        <FontAwesomeIcon
          onClick={() => window.location.reload()}
          className="hover:cursor-pointer"
          color="white"
          icon={faRotateRight}
        />
      </div>
      <div
        className={classNames(
          'absolute -translate-y-32 w-56 h-56 bg-black bg-opacity-90 rounded-xl flex flex-col gap-8 items-center justify-center',
          showFailureModal ? 'block' : 'hidden'
        )}
      >
        <h1>Failure.</h1>
        <h5 className="text-sm">The word was &apos;{answer}&apos;</h5>
        <FontAwesomeIcon
          onClick={() => window.location.reload()}
          className="hover:cursor-pointer"
          color="white"
          icon={faRotateRight}
        />
      </div>
    </>
  )
}
