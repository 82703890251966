import {Cell} from '../../components/Cell'

type Props = {
  guess: string
  answer: string
}
type States = 'correct' | 'present' | 'wrong' | 'empty'

export function FilledRow({guess, answer}: Props) {
  const guessStates: JSX.Element[] = []
  const guessSplit = guess.split('')
  const answerSplit = answer.split('')
  const letterStates: States[] = ['wrong', 'wrong', 'wrong', 'wrong', 'wrong']

  for (let i = 0; i < guess.length; i++) {
    if (guessSplit[i] === answerSplit[i]) {
      answerSplit[i] = '^'
      guessSplit[i] = '^'
      letterStates[i] = 'correct'
    }
  }

  for (let i = 0; i < guess.length; i++) {
    if (guessSplit[i] == '^') {
      continue
    }
    if (answerSplit.includes(guessSplit[i])) {
      answerSplit[answerSplit.indexOf(guessSplit[i])] = '^'
      letterStates[i] = 'present'
    } else {
      letterStates[i] = 'wrong'
    }
  }

  for (let i = 0; i < guess.length; i++) {
    guessStates.push(
      <Cell
        key={guess[i] + i}
        letter={guess[i]}
        state={letterStates[i]}
        className={'animate-flip'}
      />
    )
  }

  return (
    <div className="w-full flex justify-center items-center bg-white lg:h-20 h-1/6 flex flex-row gap-1">
      {guessStates.map((cell) => cell)}
    </div>
  )
}
