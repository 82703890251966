import type {ReactNode} from 'react'

type Props = {
  fontSize: string
  character: string | ReactNode
  handleClick(): void
}

export function SpecialKey({fontSize, character, handleClick}: Props) {
  const css =
    'h-14 w-12 rounded-md bg-letter-grey flex items-center justify-center hover:cursor-pointer ' +
    fontSize

  return (
    <div onClick={() => handleClick()} className={css}>
      {character}
    </div>
  )
}
