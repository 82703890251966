import {faDeleteLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {KeyboardLetter} from './KeyboardLetter'
import {SpecialKey} from './SpecialKey'

type Props = {
  addToGuess(s: string): void
  handleGuess(): void
  backspace(): void
  usedLetters: string[]
  presentLetters: string[]
  correctLetters: string[]
}

export function KeyboardThirdRow({
  addToGuess,
  backspace,
  handleGuess,
  correctLetters,
  usedLetters,
  presentLetters,
}: Props) {
  const letters: string[] = ['Z', 'X', 'C', 'V', 'B', 'N', 'M']

  function keyState(l: string) {
    if (correctLetters.includes(l)) {
      return 'correct'
    } else if (presentLetters.includes(l)) {
      return 'present'
    } else if (usedLetters.includes(l)) {
      return 'wrong'
    } else {
      return 'empty'
    }
  }

  return (
    <div className="w-full h-2/6 px-2 py-1 flex justify-center gap-1 items-center text-xl">
      <SpecialKey
        fontSize={'text-sm'}
        character="ENTER"
        handleClick={handleGuess}
      />
      {letters.map((letter) => (
        <KeyboardLetter
          key={letter}
          character={letter}
          addToGuess={addToGuess}
          state={keyState(letter)}
        />
      ))}
      <SpecialKey
        fontSize={'text-xl'}
        character={<FontAwesomeIcon color="letter-grey" icon={faDeleteLeft} />}
        handleClick={backspace}
      />
    </div>
  )
}
