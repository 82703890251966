import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChartLine,
  faCircleQuestion,
  faGear,
} from '@fortawesome/free-solid-svg-icons'

export function Header() {
  return (
    <div className="h-auto w-full border-b border-letter-grey flex flex-row justify-center">
      <div className="w-4/12 flex justify-center items-center text-3xl font-bold">
        WORDLE
      </div>
    </div>
  )
}
