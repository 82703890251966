import {Cell} from '../../components/Cell'

export function EmptyRow() {
  return (
    <div className="w-full flex justify-center items-center  lg:h-20 h-1/6 flex flex-row gap-1">
      {Array.from({length: 5}, (_, index) => (
        <Cell key={index} state={'empty'} letter={''} className={''} />
      ))}
    </div>
  )
}
