import {KeyboardSecondRow} from './KeyboardSecondRow'
import {KeyboardThirdRow} from './KeyboardThirdRow'
import {KeyboardTopRow} from './KeyboardTopRow'

type Props = {
  addToGuess(s: string): void
  handleGuess(): void
  backspace(): void
  usedLetters: string[]
  presentLetters: string[]
  correctLetters: string[]
}

export function Keyboard({
  addToGuess,
  backspace,
  handleGuess,
  usedLetters,
  correctLetters,
  presentLetters,
}: Props) {
  return (
    <div className="w-full h-auto flex flex-col ">
      <KeyboardTopRow
        addToGuess={addToGuess}
        usedLetters={usedLetters}
        correctLetters={correctLetters}
        presentLetters={presentLetters}
      />
      <KeyboardSecondRow
        addToGuess={addToGuess}
        usedLetters={usedLetters}
        correctLetters={correctLetters}
        presentLetters={presentLetters}
      />
      <KeyboardThirdRow
        addToGuess={addToGuess}
        handleGuess={handleGuess}
        backspace={backspace}
        usedLetters={usedLetters}
        correctLetters={correctLetters}
        presentLetters={presentLetters}
      />
    </div>
  )
}
