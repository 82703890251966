import {CurrentRow} from './CurrentRow'
import {EmptyRow} from './EmptyRow'
import {FilledRow} from './FilledRow'

type Props = {
  answer: string
  guesses: string[]
  setGuesses(g: string[]): void
  currentGuess: string
  setCurrentGuess(s: string): void
  currentRowClass: string
}

export function Board({
  answer,
  guesses,
  currentGuess,
  setCurrentGuess,
  currentRowClass,
}: Props) {
  return (
    <div className="h-auto flex flex-col gap-1 items-center justify-center w-full lg:px-24 px-5 py-2 font-bold text-4xl">
      {guesses.map((guess) => (
        <FilledRow key={guess} guess={guess} answer={answer} />
      ))}
      {guesses.length <= 5 && (
        <CurrentRow
          answer=""
          currentGuess={currentGuess}
          setCurrentGuess={setCurrentGuess}
          currentRowClass={currentRowClass}
        />
      )}

      {Array.from({length: 5 - guesses.length}, (_, index) => (
        <EmptyRow key={index} />
      ))}
    </div>
  )
}
