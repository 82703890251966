import {KeyboardLetter} from './KeyboardLetter'

type Props = {
  addToGuess(s: string): void
  usedLetters: string[]
  presentLetters: string[]
  correctLetters: string[]
}

export function KeyboardTopRow({
  addToGuess,
  correctLetters,
  usedLetters,
  presentLetters,
}: Props) {
  const letters: string[] = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P']

  function keyState(l: string) {
    if (correctLetters.includes(l)) {
      return 'correct'
    } else if (presentLetters.includes(l)) {
      return 'present'
    } else if (usedLetters.includes(l)) {
      return 'wrong'
    } else {
      return 'empty'
    }
  }

  return (
    <div className="w-full h-2/6 px-2 py-1 flex justify-center gap-1 items-center text-xl">
      {letters.map((letter) => (
        <KeyboardLetter
          key={letter}
          character={letter}
          addToGuess={addToGuess}
          state={keyState(letter)}
        />
      ))}
    </div>
  )
}
