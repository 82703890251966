import classNames from 'classnames'

export type States = 'correct' | 'present' | 'wrong' | 'empty'

type Props = {
  letter: string
  state: States
  className: string
}

export function Cell({letter, state, className}: Props) {
  const classes = `border border-2 h-16 w-16 border-letter-grey w-full flex justify-center items-center ${className}`
  return (
    <div
      className={classNames(
        classes,
        state === 'correct' ? 'bg-green' : '',
        state === 'present' ? 'bg-yellow' : '',
        state === 'wrong' ? 'bg-letter-grey' : ''
      )}
    >
      {letter.toUpperCase()}
    </div>
  )
}
