import {Cell} from '../../components/Cell'

type Props = {
  answer: string
  currentGuess: string
  setCurrentGuess(s: string): void
  currentRowClass: string
}

export function CurrentRow({answer, currentGuess, currentRowClass}: Props) {
  const currentSplit = currentGuess.split('')

  return (
    <div className="w-full flex justify-center items-center lg:h-20 h-1/6 flex flex-row gap-1">
      {currentSplit.map((l, index) => (
        <Cell
          key={l + index}
          state={'empty'}
          letter={l}
          className={currentRowClass}
        />
      ))}

      {Array.from({length: 5 - currentSplit.length}, (_, index) => (
        <Cell
          key={index}
          state={'empty'}
          letter={''}
          className={currentRowClass}
        />
      ))}
    </div>
  )
}
